<template>
    <div class="grid formgrid p-fluid justify-content-center">
        <div class="col-10 pt-6">
            <Card>
                <template #content>
                    <Panel header="Facturacion de Venta">
                        <div class="grid formgrid p-fluid">
                            <FormDropdown :label="'Sucursal'" :wrapperClass="'field col-3'"></FormDropdown>
                            <FormCalendar :label="'Fecha'" v-model="general_info.date" :wrapperClass="'field col-3'"></FormCalendar>
                            <FormInputText :label="'# Ticket'" v-model="general_info.id_ticket" :wrapperClass="'field col-3'"></FormInputText>
                            <FormInputNumber :label="'Total'" v-model="general_info.total" :wrapperClass="'field col-3'" :mode="'currency'"></FormInputNumber>
                            <Divider></Divider>
                            <div v-if="ticket" class="grid formgrid p-fluid">
                                <FormInputText v-model="invoice_info.rfc" :label="'RFC'" :wrapperClass="'field col-6'"></FormInputText>
                                <FormDropdownComplex v-model="invoice_info.regimen" :filterFields="['c_RegimenFiscal', 'Descripcion']" :labelInOption="'c_RegimenFiscal - Descripcion'" :labelInValue="'c_RegimenFiscal - Descripcion'" :wrapperClass="'field col-6'" label="Regimen Fiscal" :options="c_Regimen" optionLabel="c_RegimenFiscal" optionValue="c_RegimenFiscal" />
                                <FormInputText v-model="invoice_info.razon_social" :label="'Razon Social'" :wrapperClass="'field col-12'"></FormInputText>
                                <FormInputText v-model="invoice_info.calle" :label="'Calle'" :wrapperClass="'field col-6'"></FormInputText>
                                <FormInputText v-model="invoice_info.ext" :label="'# Ext'" :wrapperClass="'field col-2'"></FormInputText>
                                <FormInputText v-model="invoice_info.int" :label="'# Int'" :wrapperClass="'field col-2'"></FormInputText>
                                <FormInputText v-model="invoice_info.cp" :label="'Codigo Postal'" :wrapperClass="'field col-2'"></FormInputText>
                                <FormInputText v-model="invoice_info.colonia" :label="'Colonia'" :wrapperClass="'field col-4'"></FormInputText>
                                <FormInputText v-model="invoice_info.estado" :label="'Estado'" :wrapperClass="'field col-4'"></FormInputText>
                                <FormInputText v-model="invoice_info.pais" :label="'Pais'" :wrapperClass="'field col-4'"></FormInputText>
                                <FormDropdownComplex v-model="invoice_info.uso_cfdi" :filterFields="['c_UsoCFDI', 'Descripcion']" :labelInOption="'c_UsoCFDI - Descripcion'" :labelInValue="'c_UsoCFDI - Descripcion'" :wrapperClass="'field col-4'" label="Uso CFDI" :options="c_UsoCFDI" optionLabel="c_UsoCFDI" optionValue="c_UsoCFDI" />
                                <FormDropdownComplex v-model="invoice_info.forma_pago_cfdi" :filterFields="['c_FormaPago', 'Descripcion']" :labelInOption="'c_FormaPago - Descripcion'" :labelInValue="'c_FormaPago - Descripcion'" :wrapperClass="'field col-4'" label="Forma de Pago" :options="c_FormaPago" optionLabel="c_FormaPago" optionValue="c_FormaPago" />
                                <FormInputText v-model="invoice_info.email" :label="'E-mail'" :wrapperClass="'field col-4'"></FormInputText>
                                <FormInputText v-model="invoice_info.contacto" :label="'Contacto'" :wrapperClass="'field col-4'"></FormInputText>
                                <FormInputText v-model="invoice_info.telefono" :label="'Telefono'" :wrapperClass="'field col-4'"></FormInputText>
                            </div>
                        </div>
                    </Panel>
                </template>
                <template #footer>
                    <Button icon="pi pi-check" label="Facturar" />
                </template>
            </Card>
        </div>
    </div>
</template>

<script>
import FormCalendar from '../../../components/general/FormCalendar.vue';
import FormDropdown from '../../../components/general/FormDropdown.vue';
import FormDropdownComplex from '../../../components/general/FormDropdownComplex.vue';
import FormInputNumber from '../../../components/general/FormInputNumber.vue';
import FormInputText from '../../../components/general/FormInputText.vue'
import { satCatalogo } from "../../../utilities/General";



export default {
    components: { FormInputText, FormCalendar, FormDropdown, FormInputNumber, FormDropdownComplex},
    data() {
        return {
            general_info: {
                id_ticket: null,
                date: new Date(),
                total: null
            },
            invoice_info: {

            },
            ticket: null,
            c_Regimen: [],
            c_FormaPago: [],
            c_UsoCFDI: [],
        }
    },
    async mounted() {
        this.c_Regimen = await satCatalogo(2);
        this.c_FormaPago = await satCatalogo(6);
        this.c_UsoCFDI = await satCatalogo(15);
    }
}
</script>

<style>

</style>